import React from "react";
import Root from "./Root";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import { Login } from "./Pages/Login";
import { Signup } from "./Pages/Signup";
import FindPW from "./Pages/FindPW";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Root />}>
        <Route path="" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="find-pw" element={<FindPW />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <AppRouter />,
  },
]);

export default router;
