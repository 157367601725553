import { Box, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import * as S from './styled';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { findPWChangePasswordAtom, findPWEmailAtom, findPWInitAtom, findPWPasswordTestAtom, findPWSendEmailAtom, findPWVerifyNumAtom } from '../../../../ViewModels/FindPW/ViewModel';
import { findPWAtom } from '../../../../Atoms/FindPW/Atom';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const FindPWInputSection = () => {
  const [email, setEmail] = useAtom(findPWEmailAtom);
  const [verifyCode, setVerifyCode] = useAtom(findPWVerifyNumAtom);
  const [state, setState] = useAtom(findPWAtom);
  const sendEmail = useSetAtom(findPWSendEmailAtom);
  const muiTheme = useTheme();
  const isXs = useMediaQuery(muiTheme.breakpoints.down('md'));
  const navigate = useNavigate();
  const checkPassword = useAtomValue(findPWPasswordTestAtom);
  const changePassword = useSetAtom(findPWChangePasswordAtom);
  const initState = useSetAtom(findPWInitAtom);

  const handleChangePassword = () => {
    if (checkPassword && state.password === state.confirmPassword) {
      changePassword();
    }
  }

  React.useEffect(() => {
    initState();
    return () => {
      initState();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
        boxSizing: "border-box",
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        gap: "20px",
        position: "relative",

        "@media (max-width: 768px)": {
          padding: "20px",
        },
      }}
    >
      <div style={{ height: "50px" }}></div>
      {
        isXs &&
        <Stack direction="row" justifyContent={"start"} width={"100%"} position={"absolute"} left={"30px"} top={"30px"} sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          width: "100%",
          position: "absolute",
          left: "30px",
          top: "30px",

          "@media (max-width: 768px)": {
            left: "10px",
            top: "10px",
          },
        }}>
          <Button
            sx={{
              color: process.env.REACT_APP_MAIN_COLOR,
              "&:hover": {
                color: process.env.REACT_APP_MAIN_COLOR,
              },
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosNew />
            로그인으로
          </Button>
        </Stack>
      }
      <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: "black" }}>
        비밀번호 {state.mode === "find" ? "찾기" : "재설정"}
      </Typography>
      {
        state.mode === "find" ?
          <>
            <S.CustomStack>
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                이메일 입력
              </Typography>
              <S.InputContainer>
                <S.CustomInputBase
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (state.checkEmail) {
                        // 비밀번호 재설정 페이지로 이동
                        sendEmail();
                      }
                    }
                  }}
                />
                <S.CustomIconButton onClick={sendEmail} tabIndex={-1}>
                  {state.checkEmail ? (
                    state.realEmailVerifyNum !== "" ? (
                      <Typography
                        sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                      >
                        재전송
                      </Typography>
                    ) : (
                      <SendIcon
                        sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                      />
                    )
                  ) : (
                    <Tooltip title="이메일이 아닙니다.">
                      <WarningIcon sx={{ color: "red" }} />
                    </Tooltip>
                  )}
                </S.CustomIconButton>
              </S.InputContainer>
            </S.CustomStack>

            {
              state.realEmailVerifyNum !== "" && (
                <S.CustomStack>
                  <Typography
                    sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
                  >
                    이메일 인증번호
                  </Typography>
                  <S.InputContainer>
                    <S.CustomInputBase
                      value={verifyCode}
                      onChange={(e) => setVerifyCode(e.target.value)}

                    />
                    <S.CustomIconButton>
                      {state.checkuserInputEmailVerifyNum ? (
                        <CheckCircleSharpIcon
                          sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                        />
                      ) : (
                        <Tooltip title="이메일 인증번호가 다릅니다.">
                          <WarningIcon sx={{ color: "red" }} />
                        </Tooltip>
                      )}
                    </S.CustomIconButton>
                  </S.InputContainer>
                </S.CustomStack>
              )
            }

            {
              state.checkuserInputEmailVerifyNum && (
                <S.CustomButton
                  onClick={() => {
                    setState({ ...state, mode: "reset" });
                  }}
                >
                  다음
                </S.CustomButton>
              )
            }
          </> :
          <>
            <S.CustomStack>
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                PW 입력
              </Typography>
              <S.InputContainer>
                <S.CustomInputBase
                  type="password"
                  value={state.password}
                  onChange={(e) => setState({ ...state, password: e.target.value })}
                />
                <S.CustomIconButton tabIndex={-1}>
                  {checkPassword ? (
                    <CheckCircleSharpIcon
                      sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                    />
                  ) : (
                    <Tooltip title="비밀번호는 최소 8자 이상이어야 하며, 대문자, 숫자, 특수문자를 포함해야 합니다.">
                      <WarningIcon sx={{ color: "red" }} />
                    </Tooltip>
                  )}
                </S.CustomIconButton>
              </S.InputContainer>
            </S.CustomStack>

            <S.CustomStack>
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                PW 확인
              </Typography>
              <S.InputContainer>
                <S.CustomInputBase
                  type="password"
                  value={state.confirmPassword}
                  onChange={(e) => setState({ ...state, confirmPassword: e.target.value })}
                />
                <S.CustomIconButton tabIndex={-1}>
                  {checkPassword && state.password === state.confirmPassword ? (
                    <CheckCircleSharpIcon
                      sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                    />
                  ) : (
                    <Tooltip title="비밀번호와 동일해야 합니다.">
                      <WarningIcon sx={{ color: "red" }} />
                    </Tooltip>
                  )}
                </S.CustomIconButton>
              </S.InputContainer>
            </S.CustomStack>

            <S.CustomButton
              onClick={handleChangePassword}
            >
              변경
            </S.CustomButton>
          </>
      }
    </Box >
  );
};

export default FindPWInputSection;
