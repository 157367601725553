import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import NoticeModal from "./Components/Root/Modal/NoticeModal";
import WaitingModal from "./Components/Root/Modal/WaitingModal";
import ErrorModal from "./Components/Root/Modal/ErrorModal";
import SuccessModal from "./Components/Root/Modal/SuccessModal";
import ServiceAgreementModal from "./Components/Signup/Modal/ServiceAgreementModal";
import { useAtom } from "jotai";
import { checkCookieAndAutoLoginAtom } from "./ViewModels/RootViewModel";

function Root() {
  const [, checkCookieAndAutoLogin] = useAtom(checkCookieAndAutoLoginAtom);

  useEffect(() => {
    checkCookieAndAutoLogin();
  }, []);

  return (
    <>
      <SuccessModal />
      <ErrorModal />
      <WaitingModal />
      <NoticeModal />
      <ServiceAgreementModal />
      <Outlet />
    </>
  );
}

export default Root;
