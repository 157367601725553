import axios from "axios";
import { getVerifyRefreshToken } from "./AuthQueries";

export async function login(username: string, password: string) {
  const response = await axios.post(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/login/`,
    {
      email: username,
      password: password,
      site_name: process.env.REACT_APP_SITE_NAME,
    }
  );

  return response;
}

export const changePassword = async (accessToken: string, refreshToken: string, password: string) => {
  const response = await axios.put(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updatePassword`,
    {
      password: password,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  if (response.status === 403) {
    const access = await getVerifyRefreshToken(refreshToken);
    const response = await axios.put(
      `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updatePassword`,
      {
        password: password,
      },
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );
    return response;
  }
  return response;
}

export const resetPassword = async (email: string, password: string) => {
  const response = await axios.put(
    `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/updatePasswordWithoutToken`,
    {
      email: email,
      password: password,
    }
  );
  return response;
}
