import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ImageSectionProps {
  pathTo: string;
}

const ImageSection = ({ pathTo }: ImageSectionProps) => {
  const navigate = useNavigate();
  const description = pathTo === "/" ? "로그인으로" : "회원가입으로";

  return (
    <Box
      sx={{
        width: "50%",
        position: "relative",
        display: { xs: "none", md: "block" },
        ".signup-button": {
          display: "none",
        },
        "&:hover": {
          ".signup-button": {
            display: "block",
            zIndex: 10,
          },
          ".signup-image": {
            opacity: 0.5,
          },
        },
      }}
    >
      <Button
        className="signup-button"
        variant="contained"
        sx={{
          position: "absolute",
          top: "50%",
          width: "120px",
          left: `calc(50% - 60px)`,
          borderRadius: "30px",
          background: process.env.REACT_APP_MAIN_COLOR,
          "&:hover": {
            background: process.env.REACT_APP_MAIN_COLOR,
          },
        }}
        onClick={() => navigate(pathTo)}
      >
        {description}
      </Button>
      <Box
        component="img"
        src="/cute_dokgabi.webp"
        alt="Login Illustration"
        className="signup-image"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "fill",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
      />
    </Box>
  )
}

export default ImageSection;
