import { atom } from 'jotai';
import { findPWAtom, findPWInitState } from '../../Atoms/FindPW/Atom';
import { checkUserEmail, sendResetPWEmail } from '../../Queries/SignupQueries';
import { errorModalAtom, successModalAtom } from '../../Atoms/RootAtom';
import { resetPassword } from '../../Queries/Loginqueries';

export const findPWEmailAtom = atom(
  (get) => get(findPWAtom).email,
  (_get, set, email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    set(findPWAtom, (current) => ({
      ...current,
      email,
      checkEmail: emailRegex.test(email),
    }));
  }
);

export const findPWVerifyNumAtom = atom((get) => get(findPWAtom).userInputEmailVerifyNum, (_get, set, verifyNum: string) => {
  set(findPWAtom, (current) => ({
    ...current,
    userInputEmailVerifyNum: verifyNum,
    checkuserInputEmailVerifyNum: current.realEmailVerifyNum === verifyNum,
  }));
});

export const findPWSendEmailAtom = atom(null, async (get, set) => {
  const checkEmail = get(findPWAtom).checkEmail;

  if (checkEmail) {
    const email = get(findPWAtom).email;

    // checkDupEmail
    const checkResponse = await checkUserEmail(email);

    // checkResponse가 true일 경우 존재하지 않는 이메일임
    if (checkResponse === 200 || checkResponse === 400) {
      set(errorModalAtom, {
        redirectUrl: "",
        state: true,
        text: "가입된 이메일이 존재하지 않습니다.\n다시 확인해주세요.",
        title: "이메일 확인 불가",
        event: null,
        eventText: "",
      })
      return;
    }

    const response = await sendResetPWEmail(email);
    if (response) {
      set(successModalAtom, {
        state: true,
        event: () => set(successModalAtom, (prev) => ({ ...prev, state: false })),
        eventText: "확인",
        redirectUrl: "",
        text: "입력하신 이메일로 인증번호를 발송하였습니다.\n이메일을 확인해주세요.",
        title: "이메일 전송 성공",
        isClose: false,
      });
      set(findPWAtom, (current) => ({
        ...current,
        realEmailVerifyNum: response,
        userInputEmailVerifyNum: "",
        checkuserInputEmailVerifyNum: false,
      }));
    } else {
      set(errorModalAtom, {
        redirectUrl: "",
        state: true,
        text: "이메일 전송에 실패하였습니다.\n다시 시도해주세요.",
        title: "이메일 전송 오류",
        event: null,
        eventText: "",
      });
    }
  }
});

export const findPWInitAtom = atom(null, (_get, set) => {
  set(findPWAtom, findPWInitState);
});

export const findPWPasswordTestAtom = atom((get) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;
  return passwordRegex.test(get(findPWAtom).password);
});

export const findPWChangePasswordAtom = atom(null, async (get, set) => {
  const { email, password } = get(findPWAtom);

  const response = await resetPassword(email, password);
  if (response.status === 200) {
    set(successModalAtom, {
      state: true,
      event: () => {
        set(findPWAtom, findPWInitState);
        window.location.href = "/";
      },
      eventText: "확인",
      redirectUrl: "",
      text: "비밀번호 재설정에 성공하였습니다.\n다시 로그인해주세요.",
      title: "비밀번호 재설정 성공",
      isClose: false,
    });
  } else {
    set(errorModalAtom, {
      redirectUrl: "",
      state: true,
      text: "비밀번호 재설정에 실패하였습니다.\n다시 시도해주세요.",
      title: "비밀번호 재설정 오류",
      event: null,
      eventText: "",
    });
  }
});
