import React from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import Lottie from 'react-lottie';
import LottieData from './Animation/Animation - 1723387593175.json';
import { waitingModalAtom } from "../../../Atoms/RootAtom";
import * as S from './styled';

const WaitingModal: React.FC = () => {
  const [waitingModalState,] = useAtom(waitingModalAtom);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      open={waitingModalState.state}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <S.CustomBox>
        <Lottie
          options={defaultOptions}
          height={150}
          width={300}
          isClickToPauseDisabled={true}
        />
        <Typography sx={{ color: "#afafaf" }}>{waitingModalState.text}</Typography>
      </S.CustomBox>

    </Modal>
  );
};

export default WaitingModal;
