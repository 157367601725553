import { atom } from 'jotai';

interface FindPWProps {
  mode: string;
  email: string;
  checkEmail: boolean;
  checkDupEmail: boolean;
  userInputEmailVerifyNum: string;
  realEmailVerifyNum: string;
  checkuserInputEmailVerifyNum: boolean;
  password: string;
  confirmPassword: string;
}

export const findPWInitState = {
  mode: "find",
  email: "",
  checkEmail: false,
  checkDupEmail: false,
  userInputEmailVerifyNum: "",
  realEmailVerifyNum: "",
  checkuserInputEmailVerifyNum: false,
  password: "",
  confirmPassword: "",
}

export const findPWAtom = atom<FindPWProps>(findPWInitState);
