import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Collapse,
} from "@mui/material";
import { useAtom } from "jotai";
import { agreementAtom, signupModelAtom } from "../../../Atoms/SignupAtom";
import {
  marketingUsingAgreementAtom,
  privateDataAgreementAtom,
  serviceAgreementAtom,
} from "../../../Atoms/ServiceAgreementAtom";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { finallySignUpAtom } from "../../../ViewModels/SignupViewModel";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  "@media (max-width: 768px)": {
    width: "90%", // 모바일에서는 너비를 90%로 설정
    padding: 2, // 패딩을 줄임
    boxShadow: 12, // 모바일에서 그림자 크기를 줄임
  },
};

const ServiceAgreementModal: React.FC = () => {
  const [signupModel, setsignupModel] = useAtom(signupModelAtom);

  // 서비스 이용 약관 동의서
  const [serviceAgreement] = useAtom(serviceAgreementAtom);

  // 개인정보처리 방침
  const [privateDataAgreement] = useAtom(privateDataAgreementAtom);

  // 마케팅 활용 동의
  const [marketingUsingAgreement] = useAtom(marketingUsingAgreementAtom);

  const [agree, setAgree] = useAtom(agreementAtom);

  const [, finallySignUp] = useAtom(finallySignUpAtom);

  const [serviceAgreementOpen, setServiceAgreementOpen] = useState(false);
  const [privateDataAgreementOpen, setPrivateDataAgreementOpen] =
    useState(false);
  const [marketingUsingAgreementOpen, setMarketingUsingAgreementOpen] =
    useState(false);

  const navigate = useNavigate();

  const toggleServiceAgreement = () => {
    setServiceAgreementOpen(!serviceAgreementOpen);
  };

  const togglePrivateDataAgreement = () => {
    setPrivateDataAgreementOpen(!privateDataAgreementOpen);
  };

  const toggleMarketingUsingAgreement = () => {
    setMarketingUsingAgreementOpen(!marketingUsingAgreementOpen);
  };

  const handleAgreeChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedAgree = [...agree];
      updatedAgree[index] = event.target.checked;
      setAgree(updatedAgree);
    };

  const handleAllAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAgree([isChecked, isChecked, isChecked, isChecked]);
  };

  return (
    <Modal
      open={signupModel.allPass}
      onClose={() => {
        setsignupModel((current) => ({ ...current, allPass: false }));
        setAgree([false, false, false, false]);
      }}
    >
      <Box sx={style}>
        <Typography>약관동의</Typography>
        <FormGroup>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            margin="10px 0"
            padding="10px 0"
            borderTop="1px solid black"
            borderBottom="1px solid black"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree.every((value) => value)}
                  onChange={handleAllAgreeChange}
                  sx={{
                    color: "#4E229F",
                    "&.Mui-checked": {
                      color: "#4E229F",
                    },
                  }}
                />
              }
              label="전체 동의"
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={agree[0]}
                  onChange={handleAgreeChange(0)}
                  sx={{
                    color: "#4E229F",
                    "&.Mui-checked": {
                      color: "#4E229F",
                    },
                  }}
                />
              }
              label="(필수) 만 14세 이상입니다."
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={agree[1]}
                  onChange={handleAgreeChange(1)}
                  sx={{
                    color: "#4E229F",
                    "&.Mui-checked": {
                      color: "#4E229F",
                    },
                  }}
                />
              }
              label="(필수) 서비스 약관동의"
            />
            <IconButton onClick={toggleServiceAgreement}>
              {serviceAgreementOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={serviceAgreementOpen}>
            <Box
              sx={{ maxHeight: "10vw", overflowY: "auto" }}
              marginBottom="10px"
            >
              <Typography variant="body2" color="textSecondary">
                {serviceAgreement}
              </Typography>
            </Box>
          </Collapse>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={agree[2]}
                  onChange={handleAgreeChange(2)}
                  sx={{
                    color: "#4E229F",
                    "&.Mui-checked": {
                      color: "#4E229F",
                    },
                  }}
                />
              }
              label="(필수) 개인정보처리 방침"
            />
            <IconButton onClick={togglePrivateDataAgreement}>
              {privateDataAgreementOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={privateDataAgreementOpen}>
            <Box
              sx={{ maxHeight: "10vw", overflowY: "auto" }}
              marginBottom="10px"
            >
              <Typography variant="body2" color="textSecondary">
                {privateDataAgreement}
              </Typography>
            </Box>
          </Collapse>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree[3]}
                  onChange={handleAgreeChange(3)}
                  sx={{
                    color: "#4E229F",
                    "&.Mui-checked": {
                      color: "#4E229F",
                    },
                  }}
                />
              }
              label="(선택) 마케팅 활용 동의"
            />
            <IconButton onClick={toggleMarketingUsingAgreement}>
              {marketingUsingAgreementOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={marketingUsingAgreementOpen}>
            <Box
              sx={{ maxHeight: "10vw", overflowY: "auto" }}
              marginBottom="10px"
            >
              <Typography variant="body2" color="textSecondary">
                {marketingUsingAgreement}
              </Typography>
            </Box>
          </Collapse>
        </FormGroup>

        <Button
          variant={"contained"}
          onClick={() => finallySignUp({ navigate })}
          sx={{
            width: "100%",
            marginTop: "25px",
            background: "#4E229F",
            "&:hover": {
              background: "#4E229F",
            },
          }}
        >
          Sign Up
        </Button>
      </Box>
    </Modal>
  );
};

export default ServiceAgreementModal;
