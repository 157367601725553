import React from "react";

import { useAtom } from "jotai";
import * as S from "../Components/Login/styled";
import { onLoginClickAtom } from "../ViewModels/LoginViewModel";
import { useNavigate } from "react-router-dom";
import { loginValueAtom } from "../Atoms/LoginAtom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ImageSection from "../Components/ImageSection";

export function Login() {
  const [, onLoginClick] = useAtom(onLoginClickAtom);
  const [loginValue, setLoginValue] = useAtom(loginValueAtom);
  const muiTheme = useTheme();
  const isXs = useMediaQuery(muiTheme.breakpoints.down('md'));

  const navigate = useNavigate();

  return (
    <S.LoginFullContainer>
      <S.Wrapper>
        <ImageSection pathTo="/signup" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
            boxSizing: "border-box",
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto",
            gap: "20px",
            position: "relative",

            "@media (max-width: 768px)": {
              padding: "20px",
            },
          }}
        >
          {
            isXs &&
            <img src="/cute_dokgabi.webp" alt="cute_dokgabi" style={{ width: "150px", height: "150px" }} />
          }
          <S.InputField
            type="text"
            name="email"
            placeholder="Email"
            value={loginValue.username}
            onChange={(e) =>
              setLoginValue((current) => ({
                ...current,
                username: e.target.value,
              }))
            }
          />
          <S.InputField
            type="password"
            name="password"
            placeholder="Password"
            value={loginValue.password}
            onChange={(e) =>
              setLoginValue((current) => ({
                ...current,
                password: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                onLoginClick({ navigate });
              }
            }}
          />
          <S.SubmitButton
            type="submit"
            value="Login"
            onClick={() => onLoginClick({ navigate })}
          >
            Login
          </S.SubmitButton>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "90%" }}>
            <S.SignupButton onClick={() => navigate("/signup")}>
              Sign up
            </S.SignupButton>
            <S.SignupButton onClick={() => navigate("/find-pw")}>
              비밀번호 찾기
            </S.SignupButton>
          </div>
        </Box>
      </S.Wrapper>
    </S.LoginFullContainer>
  );
}
