import {
  styled as muiStyled,
  Stack,
} from "@mui/material";
import styled from "styled-components";

export const Fullcontainer = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #ececec;
`;

export const Wrapper = styled.div`
  width: 85%;
  height: 90%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 30px 40px;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

export const CustomTypeSelectStack = muiStyled(Stack)({
  width: "100%",
  gap: "30px",
  marginBottom: "10px",
  marginTop: "15px",
  alignItems: "center",
  "@media (max-width: 768px)": {
    gap: "20px", // 모바일에서 간격 줄임
    marginTop: "10px", // 모바일에서 마진 조정
  },
});
