import styled from "styled-components";

export const LoginFullContainer = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #ececec;
`;

export const Wrapper = styled.div`
  width: 85%;
  height: 90%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 30px 40px;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

export const SignupButton = styled.button`
  width: 100%;
  height: 45px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4e229f;
  font-weight: 700;

  @media (max-width: 768px) {
    height: 40px;
    font-size: 14px; /* 모바일에서는 폰트 크기 조정 */
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const InputField = styled.input`
  width: 100%;
  height: 60px;
  border: none;
  outline: none;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(164, 159, 248, 0.2);
  cursor: pointer;
  font-size: 16px;
  color: #393193;
  transition: background 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #4e229f;

  &:focus {
    border: 1.2px solid #4e229f;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    height: 50px; /* 모바일에서는 높이 조정 */
    font-size: 14px; /* 폰트 크기 조정 */
    padding: 15px; /* 여백 조정 */
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 45px;
  background: #4e229f;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  transition: background 0.3s ease;

  &:hover {
    background: #4e229f;
  }

  @media (max-width: 768px) {
    height: 40px; /* 모바일에서는 높이 조정 */
    font-size: 14px; /* 폰트 크기 조정 */
  }
`;
