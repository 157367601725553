import React, { useEffect } from "react";
import * as S from "../Components/Signup/styled";
import { Box, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import {
  changeConfirmPWValueAtom,
  changeEmailValueAtom,
  changeEmailVerifyValueAtom,
  changePWValueAtom,
  checkAllValueAtom,
  checkTypeSelectAtom,
  onEmailSendClickAtom,
  whenMovedInitinalizedAtom,
} from "../ViewModels/SignupViewModel";
import { emailSendingStateAtom, signupModelAtom } from "../Atoms/SignupAtom";
import SendIcon from "@mui/icons-material/Send";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import ServiceAgreementModal from "../Components/Signup/Modal/ServiceAgreementModal";
import { ArrowBackIosNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ImageSection from "../Components/ImageSection";

export function Signup() {
  const [whenCheckTypeSelectChange, checkTypeSelect] =
    useAtom(checkTypeSelectAtom);

  const [signupModel] = useAtom(signupModelAtom);
  const [emailSendingState] = useAtom(emailSendingStateAtom);

  const [, sendEmail] = useAtom(onEmailSendClickAtom);

  const [, changeEmailValue] = useAtom(changeEmailValueAtom);
  const [, changeEmailVerifyValue] = useAtom(changeEmailVerifyValueAtom);
  const [, changePWValue] = useAtom(changePWValueAtom);
  const [, changeConfirmPWValue] = useAtom(changeConfirmPWValueAtom);
  const [, checkAll] = useAtom(checkAllValueAtom);
  const muiTheme = useTheme();
  const isXs = useMediaQuery(muiTheme.breakpoints.down('md'));

  // 초기화 함수
  const [, whenMovedInitinalized] = useAtom(whenMovedInitinalizedAtom);

  const navigate = useNavigate();

  useEffect(() => {
    checkTypeSelect();

    // 초기화 코드 삽입
    return () => {
      whenMovedInitinalized();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <S.SignupFullcontainer>
      <ServiceAgreementModal />
      <S.Wrapper>
        <ImageSection pathTo="/" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
            boxSizing: "border-box",
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto",
            gap: "20px",
            position: "relative",

            "@media (max-width: 768px)": {
              padding: "20px",
              gap: "10px",
            },
          }}
        >
          {
            isXs &&
            <Stack direction="row" justifyContent={"start"} width={"100%"} position={"absolute"} left={"30px"} top={"30px"} sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              width: "100%",
              position: "absolute",
              left: "30px",
              top: "30px",

              "@media (max-width: 768px)": {
                left: "10px",
                top: "10px",
              },
            }}>
              <Button
                sx={{
                  color: process.env.REACT_APP_MAIN_COLOR,
                  "&:hover": {
                    color: process.env.REACT_APP_MAIN_COLOR,
                  },
                }}
                onClick={() => navigate("/")}
              >
                <ArrowBackIosNew />
                로그인으로
              </Button>
            </Stack>
          }

          <S.CustomStack>
            <Typography
              sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
            >
              이메일 입력
            </Typography>
            <S.InputContainer>
              <S.CustomInputBase
                value={signupModel.email}
                onChange={changeEmailValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    sendEmail();
                  }
                }}
              />
              <S.CustomIconButton onClick={sendEmail} tabIndex={-1}>
                {signupModel.checkEmail ? (
                  signupModel.realEmailVerifyNum !== "" ? (
                    <Typography sx={{ color: "#5340ff" }}>
                      재전송
                    </Typography>
                  ) : (
                    <SendIcon sx={{ color: "#5340ff" }} />
                  )
                ) : (
                  <Tooltip title="이메일이 아닙니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </S.InputContainer>
            {!signupModel.checkDupEmail && emailSendingState !== "" && (
              <Typography
                sx={{ flex: 1, color: "red", fontSize: "13px" }}
              >
                가입된 이메일이 있습니다.변경 후 다시 시도해 주세요.
              </Typography>
            )}
            {!signupModel.checkEmail && (
              <Typography
                sx={{ flex: 1, color: "red", fontSize: "13px" }}
              >
                이메일을 입력해주세요.
              </Typography>
            )}
          </S.CustomStack>

          {signupModel.realEmailVerifyNum !== "" && (
            <S.CustomStack>
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                이메일 인증번호
              </Typography>
              <S.InputContainer>
                <S.CustomInputBase
                  value={signupModel.userInputEmailVerifyNum}
                  onChange={changeEmailVerifyValue}
                />
                <S.CustomIconButton tabIndex={-1}>
                  {signupModel.checkuserInputEmailVerifyNum ? (
                    <CheckCircleSharpIcon sx={{ color: "#5340ff" }} />
                  ) : (
                    <Tooltip title="이메일 인증번호가 다릅니다.">
                      <WarningIcon sx={{ color: "red" }} />
                    </Tooltip>
                  )}
                </S.CustomIconButton>
              </S.InputContainer>
              {(signupModel.checkDupEmail || emailSendingState === "") &&
                signupModel.checkEmail &&
                !signupModel.checkuserInputEmailVerifyNum &&
                signupModel.userInputEmailVerifyNum === "" && (
                  <Typography
                    sx={{ flex: 1, color: "red", fontSize: "13px" }}
                  >
                    인증번호를 입력해주세요.
                  </Typography>
                )}
              {(signupModel.checkDupEmail || emailSendingState === "") &&
                signupModel.checkEmail &&
                !signupModel.checkuserInputEmailVerifyNum &&
                signupModel.userInputEmailVerifyNum !== "" &&
                !signupModel.checkuserInputEmailVerifyNum && (
                  <Typography
                    sx={{ flex: 1, color: "red", fontSize: "13px" }}
                  >
                    인증번호가 다릅니다.
                  </Typography>
                )}
            </S.CustomStack>
          )}

          <S.CustomStack>
            <Typography
              sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
            >
              PW 입력
            </Typography>
            <S.InputContainer>
              <S.CustomInputBase
                type="password"
                value={signupModel.password}
                onChange={changePWValue}
              />
              <S.CustomIconButton tabIndex={-1}>
                {signupModel.checkPassword ? (
                  <CheckCircleSharpIcon sx={{ color: "#5340ff" }} />
                ) : (
                  <Tooltip title="대문자,숫자,특수기호 포함 8글자여야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </S.InputContainer>
            {signupModel.password === "" && (
              <Typography
                sx={{ flex: 1, color: "red", fontSize: "13px" }}
              >
                비밀번호를 입력해주세요.
              </Typography>
            )}
            {signupModel.password !== "" &&
              !signupModel.checkPassword && (
                <Typography
                  sx={{ flex: 1, color: "red", fontSize: "13px" }}
                >
                  대문자, 숫자, 특수기호 하나이상 포함하셔야 합니다.
                </Typography>
              )}
          </S.CustomStack>

          <S.CustomStack>
            <Typography
              sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
            >
              PW 확인
            </Typography>
            <S.InputContainer>
              <S.CustomInputBase
                type="password"
                value={signupModel.confirmPassword}
                onChange={changeConfirmPWValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    checkAll();
                  }
                }}
              />
              <S.CustomIconButton tabIndex={-1}>
                {signupModel.checkconfirmPassword ? (
                  <CheckCircleSharpIcon sx={{ color: "#5340ff" }} />
                ) : (
                  <Tooltip title="비밀번호와 동일해야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </S.InputContainer>
            {signupModel.password === "" && (
              <Typography
                sx={{ flex: 1, color: "red", fontSize: "13px" }}
              >
                비밀번호를 먼저 입력해주세요.
              </Typography>
            )}
            {signupModel.password !== "" &&
              !signupModel.checkPassword && (
                <Typography
                  sx={{ flex: 1, color: "red", fontSize: "13px" }}
                >
                  비밀번호에는 대문자, 숫자, 특수기호 하나이상 포함하셔야
                  합니다.
                </Typography>
              )}
            {signupModel.password !== "" &&
              signupModel.checkPassword &&
              !signupModel.checkconfirmPassword && (
                <Typography
                  sx={{ flex: 1, color: "red", fontSize: "13px" }}
                >
                  비밀번호와 동일하게 입력해주세요.
                </Typography>
              )}
          </S.CustomStack>

          <S.WrapperButton onClick={checkAll}>Sign Up</S.WrapperButton>
        </Box>
      </S.Wrapper>
    </S.SignupFullcontainer>
  );
}
