import { atom } from "jotai";

interface SignupProps {
  userType: string;
  email: string;
  checkEmail: boolean; // 이메일 제대로 넣는지 체크
  checkDupEmail: boolean; // 이메일 중복 체크
  userInputEmailVerifyNum: string; // 사용자가 넣은 인증번호
  realEmailVerifyNum: string; // 이메일로 간 인증번호
  checkuserInputEmailVerifyNum: boolean; // 인증번호 맞는지 체크
  password: string; // 비밀번호
  checkPassword: boolean; // 비밀번호 규칙 맞는지
  confirmPassword: string; // 비밀번호 재확인
  checkconfirmPassword: boolean; // 비밀번호랑 동일한지
  nickname: string;
  allPass: boolean; // 다 패스했는지
}

export const signupModelAtom = atom<SignupProps>({
  userType: "",
  email: "",
  checkEmail: false,
  checkDupEmail: false,
  userInputEmailVerifyNum: "",
  realEmailVerifyNum: "",
  checkuserInputEmailVerifyNum: false,
  password: "",
  checkPassword: false,
  confirmPassword: "",
  checkconfirmPassword: false,
  nickname: "",
  allPass: false,
});

export const emailSendingStateAtom = atom("");
export const agreementAtom = atom([false, false, false, false]);
